<template>
  <div>
    <b-modal size="md" class="modal-size" hide-footer v-model="modal_service" title="Servicios">
      <b-row>
        <b-col md="12">
          <b-form-group label="Buscar:">
            <b-form-input autofocus @keyup="SearchServices" type="search" v-model="search" ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <div  class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="10%" class="text-center">#</th>
                    <th width="80%" class="text-center">Nombre</th>
                    <th width="10%" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in services" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-left">{{ item.name }}</td>
                    <td class="text-center">
                      <b-button type="button" @click="AddService(it)" variant="primary" ><i class="fas fa-plus-circle"></i></b-button >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import EventBus from "@/assets/js/EventBus";
export default {
  name: "ModalClient",
  components:{

  },
  data() {
    return {
        module: 'RoomType',
        role:2,
        modal_service: false,
        search:'',
        services:[]
      
    };
  },
  created (){
    this.SearchServices();
  },
  mounted () {
    EventBus.$on('ModalServicesShow', (role) => {
      this.role = role;
      this.modal_service = true;
    });
  },
  methods: {
    SearchServices,
    AddService,
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    }
  },
};

function SearchServices() {
  let me = this;
  let url = me.url_base + "search-services";
  let data = {search : me.search};
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json",token: me.token, module: me.module,role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
       me.services = response.data.result;
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function AddService(index) {
    let data = {
      id_service:this.services[index].id_service,
      name:this.services[index].name,
      photo:this.services[index].photo,
    }
     EventBus.$emit('AddServices',data);
}
</script>
